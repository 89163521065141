import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
// import { Button } from '@material-ui/core';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import clsx from 'clsx';

// import StoreContext from '../context/store-context';
import ProductPrice from './product-price';
// import AddToCart from './add-to-cart';

import '../styles/slick.scss';
import styles from '../styles/shop-carousel.module.scss';

let mouseStart = null;

const ShopCarousel = ({ size, overlay, products = null }) => {
	const data = useStaticQuery(
		graphql`
          query {            
            allShopifyProduct(
				sort: {
					fields: createdAt
					order: DESC
				},
              	limit: 24
            ) {
              edges {
                node {
                  id
                  title
                  handle
                  productType
                  shopifyId
                  images {
                    id
                    originalSrc
                    localFile {
                      childImageSharp {
                        fluid(quality: 90, maxWidth: 680) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                  variants {
                    shopifyId
                    price
                  }
                }
              }
            }
          }
        `
	);

	// const context = useContext(StoreContext);

	const items = products || data.allShopifyProduct.edges;
	const count = items.length;

	const getSlidesToShow = (x, y) => {
		return Math.min(count, size === 'sm' ? y : x)
	};

	const getClassName = (x, y) => {
		const slidesToShow = getSlidesToShow(x, y);

		if (count <= slidesToShow) {
			return clsx(styles.carousel, styles[overlay], 'static');
		}

		return clsx(styles.carousel, styles[overlay]);
	};

	/* 	
	const onAddToCart = (e, item) => {
		e.preventDefault();
		e.stopPropagation();

		context.addVariantToCart(
			item.shopifyId,
			item.variants[0].shopifyId,
			1
		);

		return false;
	};

	const AddToCartBtn = ({ item }) => {
		if (size === 'sm') {
			return (
				<AddToCart
					className={styles.addtocart}
					onClick={(e) => onAddToCart(e, item)}
				/>
			);
		}

		return (
			<Button
				type='button'
				size='small'
				color='inherit'
				variant='text'
				className={styles.addtocartbtn}
				onClick={(e) => onAddToCart(e, item)}
			>
				Add to cart
            </Button>
		);
	}
	*/

	return (
		<Slider
			className={getClassName(3, 4)}
			dots={false}
			infinite={true}
			swipeToSlide={true}
			slidesToShow={getSlidesToShow(3, 4)}
			slidesToScroll={1}
			rows={1}
			variableWidth={true}
			centerMode={true}
			responsive={[
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: getSlidesToShow(2, 3),
						className: getClassName(2, 3)
					}
				},
				{
					breakpoint: 840,
					settings: {
						slidesToShow: getSlidesToShow(1, 2),
						className: getClassName(1, 2)
					}
				},
				{
					breakpoint: 580,
					settings: {
						slidesToShow: 1,
						className: getClassName(1, 1)
					}
				}
			]}
		>
			{items.map((x) => (
				<div
					key={x.node.id}
					className={styles.wrapper}
				>
					<Link
						to={`/product/${x.node.handle}/`}
						className={clsx(styles.card, size === 'sm' ? styles.sm : null)}
						onMouseDown={async (e) => mouseStart = { x: e.clientX, y: e.clientY }}
						onClick={(e) => {
							if (mouseStart) {
								const movementX = Math.abs(e.clientX - mouseStart.x);
								const movementY = Math.abs(e.clientY - mouseStart.y);

								if (movementX < 10 && movementY < 10) {
									mouseStart = null;
									return true;
								}
							}

							e.preventDefault();
							mouseStart = null;
							return false;
						}}
					>
						<Img
							className={styles.photo}
							fluid={x.node.images[0].localFile.childImageSharp.fluid}
							alt={x.node.handle}
						/>
						<div className={styles.footer}>
							<div className={styles.top}>
								<div className={styles.title}>{x.node.title}</div>
								<div className={styles.price}>
									<ProductPrice
										value={x.node.variants[0].price}
										showCompare={true}
									/>
								</div>
							</div>
							{/*
							<div className={styles.bottom}>
								{size !== 'sm' &&
									<small>{x.node.productType}</small>
								}
								<AddToCartBtn item={x.node} />
							</div>
							*/}
						</div>
					</Link>
				</div>
			))}
		</Slider>
	);
};

ShopCarousel.props = {
	size: PropTypes.oneOf(['sm', 'lg']).isRequired,
	overlay: PropTypes.oneOf(['none', 'primary', 'secondary']).isRequired
};

export default ShopCarousel;
