import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { ChevronDown } from 'react-feather';

import styles from '../styles/faq.module.scss';
import buttonStyles from '../styles/buttons.module.scss';

const FAQ = () => (
    <div className={styles.container}>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>WHAT IF I DON'T LIKE WHAT I GOT?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>
                        {'Returns are quick and easy! We offer free returns on all subscription and store orders within 30 days of receipt.'}
                        <br /><br />
                        {' To initiate a return please '}
                        <button
                            type='button'
                            className={clsx(buttonStyles.link, buttonStyles.secondary, buttonStyles.bold)}
                            onClick={() => window.zE('webWidget', 'open')}
                        >
                            contact us
                        </button >
                        {' and we will get back to you within 24 hours.'}
                    </p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel2-content'
                    id='panel2-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>WHAT WILL I GET IN MY SHIPMENT?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>Every shipment contains one piece of handmade jewelry from our featured brand that month, conveniently stored in our reusable cotton pouch and environmentally friendly packaging.</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel3-content'
                    id='panel3-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>WHEN WILL MY ORDER SHIP?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>Subscriptions ship at the beginning of every month. Subscribe before our next shipment is sent out!<br /><br />For example, if you subscribe on January 15th, your subscription order will be shipped out by February 4th.</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel4-content'
                    id='panel4-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>DO YOU SHIP OUTSIDE THE US?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>Shipping for our subscription is currently only available to the United States but we may offer shipping to Canada soon!</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel5-content'
                    id='panel5-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>WHAT IS THE MATERIAL OF THE JEWELRY?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>All of our jewelry is made of lead-free and hypoallergenic materials such as 14k gold, gold filled, gold plated, and sterling silver.</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={styles.cell}>
            <ExpansionPanel
                square={true}
                classes={{
                    root: styles.panel
                }}
                itemScope=''
                itemProp='mainEntity'
                itemType='https://schema.org/Question'
            >
                <ExpansionPanelSummary
                    expandIcon={<ChevronDown />}
                    aria-controls='panel6-content'
                    id='panel6-header'
                    classes={{
                        root: styles.summary,
                        content: styles.content,
                        expandIcon: styles.expand
                    }}
                >
                    <h6 itemProp='name'>HOW DO I CANCEL MY SUBSCRIPTION?</h6>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: styles.details
                    }}
                    itemScope=''
                    itemProp='acceptedAnswer'
                    itemType='https://schema.org/Answer'
                >
                    <p itemProp='text'>You can cancel your subscription anytime in <Link to='/my-account/'>your account page</Link>.<br /><br />Under the "Subscriptions" section, you can click the "cancel" button to cancel your subscription.<br /><br />Subscription will remain active until the end of your billing cycle, and you can still use your member discount to shop in our store.</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    </div>
);

export default FAQ;
