import React from 'react';

const BracketRight = ({ className }) => (
	<svg viewBox="0 0 71 370" className={className}>
		<g id="right">
        <path d="M38.5458129,27.7912105 C31.2782287,29.4060303 27.5132025,36.9295315 28.4179223,44.0123548 C29.4449284,52.0574747 36.7403501,56.901934 44.4921085,56.8249902 C65.2022379,56.6191407 71.9180425,29.2891158 63.400752,13.7125004 C53.2122153,-4.92387855 26.2018559,-3.22911722 13.0516048,10.7816426 C-0.765752965,25.5018838 -2.92415587,51.372975 3.35221334,69.8624614 C5.25013649,75.4553736 8.08459374,80.5416561 11.8844168,85.0513601 C15.9536675,89.8838281 20.9952433,93.8859031 24.6847105,99.0471308 C32.0318304,109.325618 30.2293503,123.360361 20.7466939,131.615327 C18.2025423,133.829709 21.9079167,137.526007 24.4371553,135.324616 C34.8613167,126.251248 37.6947798,110.192984 30.4232188,98.2756946 C26.0497437,91.1069342 19.0774362,86.177537 14.1024716,79.5074121 C8.40671375,71.8719927 6.00572664,62.2400325 5.57523909,52.832908 C4.81865475,36.2999916 9.17622267,15.9878374 25.3826371,8.06163056 C34.007301,3.84571191 44.6839886,3.99160528 52.7002036,9.53755184 C60.6746622,15.052521 62.8628911,25.3959612 61.2940473,34.5642602 C59.6625691,44.0932956 52.0022769,53.7452412 41.3096821,51.2670525 C36.633971,50.1828449 33.3799623,46.5794785 33.4873357,41.6800594 C33.5788019,37.5440823 35.7570887,33.777835 39.9347069,32.8495135 C43.211582,32.1210459 41.8246764,27.0617437 38.5458129,27.7912105"></path>
        <path d="M7.81362735,80.7402615 C-1.42969671,103.209165 6.57299979,129.337935 27.0189012,142.511256 C31.4564154,145.37059 36.2652196,147.127566 41.18581,148.931514 C47.7233083,151.330116 53.5381886,154.088508 53.5831027,161.939932 C53.6160397,167.695577 53.0591047,172.623705 56.1092719,177.822674 C58.6025041,182.075196 62.3583219,184.958515 66.8647044,186.796444 C69.9857361,188.070701 71.3441384,182.995659 68.2580399,181.737392 C62.5639287,179.414746 58.8121033,174.041878 58.5785499,167.904455 C58.3879145,162.871389 59.6085802,157.949258 56.9047507,153.331949 C52.3744141,145.597458 42.1848994,144.368174 34.7860482,140.847226 C12.770149,130.368334 3.69949477,104.420459 12.8659658,82.1354482 C14.1505094,79.0152669 9.08220146,77.6560592 7.81362735,80.7402615"></path>
        <path d="M40.4882135,337.06398 C32.6285442,335.340697 31.8764083,324.01056 38.318397,320.075763 C46.3331252,315.179483 56.1753321,320.730731 59.8776129,328.248878 C63.812482,336.237283 63.8195301,347.92885 58.5676681,355.36313 C53.170816,362.999091 42.7577107,365.944447 33.7975265,364.401879 C14.5923853,361.092097 6.56356093,341.82747 5.70771685,324.486809 C5.23045792,314.825041 6.38735774,304.769894 10.8347263,296.042654 C14.8149048,288.232966 21.8116819,283.066112 27.3424484,276.46452 C37.7948218,263.989189 37.5451167,245.62614 24.7930399,234.674406 C22.2325558,232.475872 18.4779174,236.168051 21.0555185,238.381561 C29.6522205,245.763922 32.4271691,258.442933 26.6879794,268.40922 C21.2659554,277.823378 11.3280953,283.601268 6.2705602,293.396825 C-2.81850397,311.002069 -2.09254681,336.808383 8.87836746,353.529021 C19.7214086,370.053968 44.6868839,376.494814 59.9511148,361.779014 C73.137155,349.064061 70.0782675,319.796204 51.0946389,314.029296 C42.6560162,311.466337 31.765652,314.548477 29.2253054,323.778925 C27.1461077,331.333015 30.9742479,340.340814 39.0816086,342.118012 C42.3972493,342.844866 43.8129161,337.791832 40.4882135,337.06398"></path>
        <path d="M12.8662709,288.865324 C3.7006561,266.58141 12.7664603,240.62865 34.7866927,230.152802 C42.1866566,226.633868 52.3743328,225.398594 56.9057378,217.669133 C59.4628874,213.304696 58.5096954,208.721387 58.5665875,203.945192 C58.6424436,197.465997 62.1567777,191.751352 68.2592027,189.262814 C71.3393604,188.005552 69.9919162,182.928533 66.8658456,184.203785 C62.3623877,186.039706 58.6025193,188.92801 56.1102466,193.177515 C53.0430644,198.404445 53.6658831,203.279583 53.5840383,209.060188 C53.4762428,216.661724 48.2311915,219.457083 41.8293344,221.831688 C36.6960711,223.73557 31.6566298,225.501533 27.0194255,228.488779 C6.57220939,241.663043 -1.42961291,267.7897 7.81385427,290.260505 C9.08045181,293.339696 14.1528306,291.990489 12.8662709,288.865324"></path>
		</g>
	</svg>
);

export default BracketRight;
