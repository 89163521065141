import React from 'react';

const BracketLeft = ({ className }) => (
	<svg viewBox="0 0 71 370" className={className}>
		<g id="left">
        <path d="M30.5120027,32.9364854 C38.3716488,34.6597754 39.1237825,45.987961 32.6808059,49.9247702 C24.6661014,54.8210704 14.8249302,49.2688017 11.1226603,41.7506245 C7.18780288,33.7631853 7.18075478,22.0715712 12.4326014,14.6372621 C17.8294376,7.00027177 28.2415053,4.05390513 37.20267,5.59847619 C56.4067478,8.90727288 64.4355485,28.1729763 65.2923969,45.5137068 C65.7696545,55.1735162 64.6117512,65.2297021 60.1643957,73.9569779 C56.1852358,81.7666966 49.1884793,86.9335714 43.6577291,93.5351899 C33.2053864,106.009573 33.4540839,124.373694 46.2071301,135.325472 C48.7676067,137.524015 52.5222339,133.832819 49.9446405,131.6193 C41.3479637,124.235911 38.5730233,111.55685 44.3121962,101.591521 C49.7342042,92.1763268 59.6710282,86.3984129 64.7295553,76.6028172 C73.8185927,58.9985011 73.0926376,33.191085 62.1207488,16.4703794 C51.2787465,-0.0546339229 26.3133446,-6.4945068 11.0491586,8.22135154 C-2.13684267,20.9353579 0.921028893,50.2043305 19.9056085,55.9702632 C28.3442063,58.5332328 39.2335316,55.4520781 41.7738708,46.2205952 C43.8540692,38.6654761 40.0259402,29.6596384 31.9175966,27.8824332 C28.6029725,27.1555762 27.1873099,32.2076315 30.5120027,32.9364854"></path>
        <path d="M57.1332216,82.1346975 C66.2997986,104.416765 57.23403,130.371701 35.212886,140.846621 C27.8129512,144.366578 17.625315,145.599863 13.094926,153.331375 C10.5367883,157.694843 11.4909746,162.278183 11.4340828,167.054411 C11.3582269,173.534649 7.84290856,179.247334 1.7415056,181.736889 C-1.33963802,182.994159 0.00780087818,188.072212 3.13485723,186.795953 C7.63829747,184.959019 11.3971529,182.071695 13.890414,177.822161 C16.956586,172.595195 16.3347679,167.719025 16.4166124,161.939379 C16.5244075,154.337791 21.7684401,151.543413 28.1712701,149.168791 C33.3045133,147.264896 38.3439348,145.498921 42.9801227,142.510656 C63.4282566,129.336302 71.4290493,103.209466 62.1866166,80.7395072 C60.920024,77.6602944 55.8476651,79.0095113 57.1332216,82.1346975"></path>
        <path d="M31.4536916,342.209378 C38.7213095,340.594563 42.4873474,333.071082 41.5826234,325.987278 C40.5556125,317.94218 33.260157,313.096734 25.5083626,313.175677 C4.79813716,313.381525 -1.91769853,340.710478 6.59863729,356.28805 C16.7882154,374.924379 43.7987,373.228623 56.9490122,359.217901 C70.7654398,344.4967 72.9238527,318.626678 66.6484486,300.137241 C64.7505166,294.544344 61.915052,289.459074 58.1162055,284.948383 C54.046936,280.116928 49.0053368,276.113864 45.3158525,270.95265 C37.9686985,260.67419 39.771187,246.639486 49.2538873,238.385541 C51.7980507,236.171165 48.0926592,232.473877 45.5634089,234.675263 C35.1382049,243.749606 32.3057229,259.806827 39.5773176,271.725084 C43.9498187,278.893825 50.9221586,283.823209 55.8971463,290.492316 C61.5939247,298.128715 63.994923,307.75965 64.4254125,317.167748 C65.1810062,333.699621 60.823418,354.012719 44.6179226,361.936906 C35.9932188,366.154812 25.3164816,366.008919 17.3002295,360.462988 C9.32573385,354.946035 7.13749487,344.604621 8.7063459,335.436347 C10.3368375,325.906338 17.9981594,316.254418 28.6898096,318.7326 C33.3655424,319.816805 36.6205603,323.420161 36.5121923,328.319567 C36.4217199,332.455533 34.2434229,336.22277 30.0657854,337.151089 C26.7879008,337.878555 28.1758071,342.937844 31.4536916,342.209378"></path>
        <path d="M62.1868022,290.260028 C71.429192,267.789228 63.4274383,241.662575 42.9803975,228.488313 C38.5438508,225.629992 33.7350134,223.873024 28.813391,222.067085 C22.2768455,219.670493 16.460927,216.912113 16.4170107,209.059726 C16.3840734,203.304107 16.9400142,198.377001 13.890824,193.177055 C11.3975746,188.925553 7.64073277,186.040247 3.13531727,184.203327 C0.014264041,182.930074 -1.34414768,188.003095 1.74197211,189.262355 C7.43512453,191.583991 11.187974,196.957834 11.4215289,203.096229 C11.6121657,208.128273 10.3914916,213.051381 13.0953397,217.668669 C17.6247096,225.402126 27.8142946,226.632403 35.2131969,230.152336 C57.2302462,240.631181 66.3009631,266.578939 57.1334307,288.864848 C55.8498764,291.984016 60.9182193,293.344217 62.1868022,290.260028"></path>
		</g>
	</svg>
);

export default BracketLeft;
