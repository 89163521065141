import React, { PureComponent } from 'react';
import moment from 'moment';
import 'moment-timezone';

import styles from '../styles/shipment-counter.module.scss';

const pad = (num) => ('0' + num).substr(-2);

export default class ShipmentCounter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            deadline: null,
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00'
        };

        this._interval = null;

        this.initialize = this.initialize.bind(this);
        this.update = this.update.bind(this);
    }

    async componentDidMount() {
        await this.initialize();
        await this.update();

        this._interval = setInterval(this.update, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    async initialize() {
        const deadline = moment()
            .tz('America/Los_Angeles')
            .add(1, 'months')
            .date(1)
            .startOf('day')
            .utc();

        await this.setState({ deadline });
    }

    async update() {
        const { deadline } = this.state;

        if (deadline) {
            const now = moment.utc();

            if (now.isAfter(deadline)) {
                clearInterval(this._interval);

                await this.setState({
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00'
                });
                return;
            }

            const duration = moment.duration(deadline.diff(now));

            const days = pad(duration.days());
            const hours = pad(duration.hours());
            const minutes = pad(duration.minutes());
            const seconds = pad(duration.seconds());

            await this.setState({
                days,
                hours,
                minutes,
                seconds
            });
        }
    }

    render() {
        const {
            days,
            hours,
            minutes,
            seconds
        } = this.state;

        return (
            <ul className={styles.container}>
                <li>
                    <div className={styles.counter}>{days}</div>
                    <div className={styles.unit}>DAYS</div>
                </li>
                <li>
                    <div className={styles.counter}>{hours}</div>
                    <div className={styles.unit}>HOURS</div>
                </li>
                <li>
                    <div className={styles.counter}>{minutes}</div>
                    <div className={styles.unit}>MINUTES</div>
                </li>
                <li>
                    <div className={styles.counter}>{seconds}</div>
                    <div className={styles.unit}>SECONDS</div>
                </li>
            </ul>
        );
    }
}
