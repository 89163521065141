import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button } from '@material-ui/core';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { ShoppingCart } from 'react-feather';
import clsx from 'clsx';

import SEO from '../components/seo';
import ShipmentCounter from '../components/shipment-counter';
import ShopCarousel from '../components/shop-carousel';
import FAQ from '../components/faq';
import LazyInstagram from '../components/lazy-instagram';
import BracketLeft from '../components/bracket-left';
import BracketRight from '../components/bracket-right';
import { formatCleanPrice } from '../utils/format-number';

import styles from '../styles/index.module.scss';
import buttonStyles from '../styles/buttons.module.scss';

// import order from '../images/icons/order.svg';
// import delivery from '../images/icons/delivery.svg';
//import diamond from '../images/icons/diamond.svg';
// import ring from '../images/icons/ring.svg';
// import magnify from '../images/icons/magnify.svg';
// import key from '../images/icons/key.svg';
// import chest from '../images/icons/chest.svg';

// const getLabelClass = (i) => {
//     switch (i) {
//         case 0:
//             return styles.red;

//         case 1:
//             return styles.blue;

//         case 2:
//             return styles.green;

//         default:
//             return null;
//     }
// };

// const getButtonClass = (i) => {
//     switch (i) {
//         case 0:
//             return null;

//         case 1:
//             return buttonStyles.info;

//         case 2:
//             return buttonStyles.success;

//         default:
//             return null;
//     }
// };

const IndexPage = ({ data }) => (
	<>
		<SEO
			title='Roselium - Monthly Jewelry Subscription Box'
			useTemplate={false}
			path='/'
			jsonld={[
				{
					"@context": "https://schema.org",
					"@type": "WebSite",
					"url": "https://www.roselium.com/"
				},
				{
					"@context": "http://schema.org",
					"@type": "Organization",
					"name": "Roselium",
					"url": "https://www.roselium.com/",
					"logo": "https://cdn.roselium.com/images/icon.png",
					"email": "hello@roselium.com",
					"sameAs": [
						"https://twitter.com/roseliumjewelry",
						"https://www.facebook.com/roseliumjewelry/",
						"https://www.instagram.com/roselium_jewelry/"
					],
					"address": {
						"@type": "PostalAddress",
						"streetAddress": "PO Box 17777",
						"addressLocality": "Los Angeles",
						"addressRegion": "CA",
						"postalCode": "91416",
						"addressCountry": "US"
					},
					"contactPoint": [
						{
							"@type": "ContactPoint",
							"telephone": "+1-877-959-9877",
							"contactType": "customer service",
							"contactOption": "TollFree",
							"areaServed": "US"
						}
					]
				}
			]}
		/>
		<BackgroundImage
			Tag='section'
			className={clsx(styles.banner, styles.botbg)}
			fluid={data.banner.childImageSharp.fluid}
			durationFadeIn={200}
			loading='eager'
		>
			<div className={styles.content}>
				<div className={styles.intro}>
					<h1>A Unique Jewelry Subscription</h1>
					<h4>With our subscription, you get to keep a new piece from a new brand every month. <br /> We partner with local jewelers to offer you variety, value, and high quality.</h4>
					<div className={styles.actions}>
						<Button
							variant='contained'
							size='large'
							color='primary'
							component={Link}
							to='/monthly-jewelry-subscription/'
							className={styles.joinnow}
						>
							BECOME A MEMBER
                        </Button>
						<Button
							variant='contained'
							size='large'
							color='default'
							component={Link}
							to='/store/'
							className={styles.learnmore}
						>
							SHOP OUR STORE
                        </Button>
					</div>
				</div>
				<Link
					to='/store/brand/rose-gypsy/'
					className={styles.featured}
				>
					<Img
						fluid={data.bannerfeatured.childImageSharp.fluid}
						alt='Featured'
						className={styles.img}
						style={{
							overflow: 'hidden',
							borderRadius: '50%'
						}}
						imgStyle={{
							overflow: 'hidden',
							borderRadius: '50%'
						}}
					/>
					<div className={styles.shadow}></div>
				</Link>
			</div>
		</BackgroundImage>
		<section className={styles.introducing}>
			<div className={styles.content}>
				<h2>Featured in Last Month's Box</h2>
				<h6>INTRODUCING...</h6>
				<div className={styles.split}>
					<div className={styles.info}>
						<Img
							fixed={data.featuredLogo.childImageSharp.fixed}
							alt='Rose Gypsy Logo'
							className={styles.featuredLogo}
						/>
						<h6>Rose Gypsy</h6>
						<p>Founded in 2014 by Rachel Karcich in Los Angeles, Rose Gypsy is a one stop shop for all things Jewelry. As the designer and founder, Rachel infuses each one of her pieces with her own life affirming and healing energy. For all things simple and fresh, look no further and get lost in the world of Rose Gypsy.</p>
						<Button
							className={styles.learnMore}
							variant='outlined'
							color='primary'
							component={Link}
							to='/our-partners/rose-gypsy/'
						>
							VIEW PARTNER PAGE
						</Button>
					</div>
					<Img
						fluid={data.featuredGraphic.childImageSharp.fluid}
						alt='Featured - Rose Gypsy'
						className={styles.featuredGraphic}
					/>
				</div>
			</div>
		</section>
		<section className={styles.howitworks}>
			<div id='how_it_works' className={styles.anchor}></div>
			<div className={styles.content}>
				<Img
					fluid={data.jewelrysubscription.childImageSharp.fluid}
					alt='Jewelry Subscription'
					className={styles.mobileimg}
				/>
				<h2>How it Works</h2>
				<h6>SHINE IS JUST A CLICK AWAY</h6>
				<div className={styles.split}>
					<Img
						fluid={data.jewelrysubscription.childImageSharp.fluid}
						alt='Jewelry Subscription'
						className={styles.desktopimg}
					/>
					<ol>
						<li>
							<div className={styles.step}><span>1</span></div>
							<div className={styles.desc}>
								<h5>SIGN UP</h5>
								<p>Get a 50% discount for your first month!<br />Checkout is fast and easy.<br />Cancel anytime. Zero commitment.</p>
							</div>
						</li>
						<li>
							<div className={styles.step}><span>2</span></div>
							<div className={styles.desc}>
								<h5>GET IT MONTHLY</h5>
								<mark>Like what you got?</mark>
								<p>Awesome! Keep it because it is yours forever!<br />Plus you can shop for more of the same brand with a 25% member discount.</p>
								<mark>Not your style?</mark>
								<p>No worries! Send it back to us for free and get a refund for the month. It's that easy.</p>
							</div>
						</li>
						<li>
							<div className={styles.step}><span>3</span></div>
							<div className={styles.desc}>
								<h5>EXPLORE</h5>
								<p>Explore new jewelry brands every month and discover new and exciting styles.</p>
							</div>
						</li>
					</ol>
				</div>
			</div>
		</section>
		{/*
       <section className={styles.whychoose}>
            <div className={styles.content}>
                <h2>Why Choose Roselium?</h2>
                <h6>WE'RE YOUR ONE-STOP SHOP</h6>
                <ul>
                    <li>
                        <img src={magnify} alt='Diamond Icon' />
                        <h5>HIGH QUALITY</h5>
                        <p>We carefully select brands that meet our quality standards to find jewelry that is meant to last.</p>
                    </li>
                    <li>
                        <img src={key} alt='Key Icon' />
                        <h5>UNIQUE</h5>
                        <p>Every month is a new brand and a new style. Explore and find your unique style!</p>
                    </li>
                    <li>
                        <img src={chest} alt='Chest Icon' />
                        <h5>VALUE</h5>
                        <p>Your membership includes an exclusive discount to our store. Get the most out of your membership!</p>
                    </li>
                </ul>
            </div>
        </section>
        */}
		<section className={styles.subscribe}>
			<div className={styles.content}>
				<h2>Become a Member</h2>
				<h5>Enjoy the perks of a Roselium membership</h5>
				<ul className={styles.breakdown}>
					<li className={clsx(styles.bracket, styles.left)}>
						<BracketLeft />
					</li>
					{data.allSubscriptionsJson.edges[0].node.plans.map((x, i) => (
						<li
							key={x.id}
							className={styles.subscription}
						>
							<div className={styles.topbar}><span className={styles.red}>{x.interval}</span></div>
							{x.promo &&
								<div className={styles.promo}>50%<br />OFF</div>
							}
							{x.promo &&
								<>
									<div className={styles.firstMonthCost}>
										<span className={styles.price}>{formatCleanPrice(x.firstMonth)}</span>
										<small>FIRST MONTH</small>
									</div>
								</>
							}
							<div className={styles.cost}>{x.promo && 'then '}{formatCleanPrice(x.price)}<span>/</span>mo{x.promo && ' after'}</div>
							<ul>
								<li>{x.storeDiscount}% Store Discount</li>
								<li>Free Shipping &amp; Returns</li>
								<li>{x.description}</li>
								<li>Cancel anytime</li>
							</ul>
							<Button
								variant='contained'
								color='primary'
								size='large'
								component={Link}
								className={styles.joinNow}
								to='/checkout/subscribe/'
								state={
									{
										product_id: data.allSubscriptionsJson.edges[0].node.id,
										plan_id: x.id,
										discount_code: x.discountCode
									}
								}
							>
								<ShoppingCart size={20} className={styles.icon} />
								JOIN NOW
                            </Button>
						</li>
					))}
					<li className={clsx(styles.bracket, styles.right)}>
						<BracketRight />
					</li>
				</ul>
			</div>
		</section>
		<BackgroundImage
			Tag='section'
			className={clsx(styles.nextshipment, styles.fixedbg)}
			fluid={data.nextshipment.childImageSharp.fluid}
		>
			<div className={styles.content}>
				<h2>NEXT SHIPMENT</h2>
				<h6>DON'T MISS OUT</h6>
				<ShipmentCounter />
				<Button
					variant='contained'
					size='large'
					fullWidth={true}
					component={Link}
					to='/monthly-jewelry-subscription/'
					className={clsx(buttonStyles.whiteContained, styles.join)}
				>
					JOIN THE CLUB
                </Button>
			</div>
		</BackgroundImage>
		<section className={styles.store}>
			<div className={styles.content}>
				<h2>Don't Want to Wait? Shop now!</h2>
				<h6>DISCOUNT FOR SUBSCRIPTION MEMBERS</h6>
				<ShopCarousel
					size='lg'
					overlay='secondary'
				/>
				<Button
					className={styles.viewstore}
					variant='contained'
					color='primary'
					size='large'
					fullWidth={true}
					component={Link}
					to='/store/all/'
				>
					CHECK OUT OUR STORE
                </Button>
			</div>
		</section>
		{/*
        <section className={styles.partners}>
            <div className={styles.content}>
                <h4>OUR PARTNERS</h4>
                <div className={styles.scroller}>
                    <ul>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo1.childImageSharp.fixed}
                                alt='Partner Logo 1'
                            />
                        </li>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo2.childImageSharp.fixed}
                                alt='Partner Logo 2'
                            />
                        </li>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo3.childImageSharp.fixed}
                                alt='Partner Logo 3'
                            />
                        </li>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo4.childImageSharp.fixed}
                                alt='Partner Logo 4'
                            />
                        </li>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo5.childImageSharp.fixed}
                                alt='Partner Logo 5'
                            />
                        </li>
                        <li>
                            <Img
                                className={styles.img}
                                fixed={data.partnerlogo6.childImageSharp.fixed}
                                alt='Partner Logo 6'
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        */}
		<section className={styles.faq}>
			<div id='faq' className={styles.anchor}></div>
			<div className={styles.content}>
				<h4>FREQUENTLY ASKED QUESTIONS</h4>
				<FAQ />
				<Button
					className={clsx(buttonStyles.default, styles.contactus)}
					variant='outlined'
					size='medium'
					onClick={() => window.zE('webWidget', 'open')}
				>
					STILL HAVE QUESTIONS? <br /> PLEASE CONTACT US!
                </Button>
			</div>
		</section>
		<section className={styles.instagram}>
			<div className={styles.content}>
				<h4>FOLLOW US ON INSTAGRAM</h4>
				<div className={styles.embeds}>
					<LazyInstagram
						url='https://www.instagram.com/p/B5vajwJh0P0/'
						className={styles.cell}
						embedClass={styles.embed}
					/>
					<LazyInstagram
						url='https://www.instagram.com/p/B4c-P1Dhijq/'
						className={styles.cell}
						embedClass={styles.embed}
					/>
					<LazyInstagram
						url='https://www.instagram.com/p/B34_7xVBBZU/'
						className={styles.cell}
						embedClass={styles.embed}
					/>
				</div>
			</div>
		</section>
	</>
);

export const query = graphql`
    query {
        allSubscriptionsJson(
            filter: {
                handle: { eq: "monthly-jewelry-subscription" }
            }            
        ) {
            edges {
                node {
                    id
                    plans {
                        id
                        title
                        description
                        interval
                        price
                        storeDiscount
                        promo
                        firstMonth
                        discountCode
                    }
                }
            }
        }

        banner: file(relativePath: { eq: "banner-silver.jpg" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 4096) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        bannerfeatured: file(relativePath: { eq: "banner-featured-20200405.jpg" }) {
            childImageSharp {
                fluid(quality: 96, maxWidth: 840) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
		}
		
		featuredLogo: file(relativePath: { eq: "partners/rose-gypsy.png" }) {
            childImageSharp {
                fixed(quality: 96, width: 260) {
                    ...GatsbyImageSharpFixed
                }
            }
		}
		
        featuredGraphic: file(relativePath: { eq: "rose-gypsy-featured2.jpg" }) {
            childImageSharp {
                fluid(quality: 96, maxWidth: 640) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
		}

        jewelrysubscription: file(relativePath: { eq: "jewelry-subscription-1.jpg" }) {
            childImageSharp {
                fluid(quality: 96, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }

        nextshipment: file(relativePath: { eq: "rose-paint.jpg" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 4096) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
}`;

export default IndexPage;
