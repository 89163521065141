import React, { useState } from 'react';
import InstagramEmbed from 'react-instagram-embed';
import LazyLoad from 'react-lazyload';
import clsx from 'clsx';

import styles from '../styles/lazy-instagram.module.scss';

const LazyInstagram = ({ url, className, embedClass }) => {
    const [loading, setLoading] = useState(true);

    return (
        <div className={clsx(styles.placeholder, className, loading && styles.loading)}>
            <LazyLoad once>
                <InstagramEmbed
                    className={clsx(styles.embed, embedClass)}
                    url={url}
                    maxWidth={320}
                    hideCaption={true}
                    containerTagName='div'
                    protocol='https'
                    onAfterRender={() => setTimeout(() => setLoading(false), 500)}
                />
            </LazyLoad>
        </div>
    );
};

export default LazyInstagram;